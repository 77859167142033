import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { AdaptiveHostedVideo } from "../components/hosted-video.js";
import SeaDragonImage from "../images/case-studies/sea-dragon.png";

const HeaderContainer = styled.div`
  width: 100%
  display: flex;
  color:  ${ siteColors.light };
font-size 3em;
justify-contents: center;
align-items: center;
margin-top: 1em;
  @media (max-width: ${ breakpoints.desktopSD }px) {
    font-size: 2.4em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 2em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.6em;
  }
  @media (max-width: ${ breakpoints.mediumPhone }px) {
    font-size: 1.4em;
  }
`;

const CaseStudiesContainer = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   font-size: 1.2em;
`;

const CaseStudyContainer = styled.div`
margin-top: 3em;
width: 80%;
max-width: 1380px;
  border-bottom: 1px solid ${ siteColors.darkest};
  background: ${ siteColors.darker };
   color:  ${ siteColors.light };
border-radius: 1em;
  padding-bottom: 3em;
  @media (max-width: ${ breakpoints.desktopSmallHD }px) {
    padding-bottom: 2em;
  }
  @media (max-width: ${ breakpoints.desktopSD }px) {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    width: 90%
  }
`;

const CaseStudyHeader=styled.div`
padding: 0.6em;
margin-bottom: 1em;
width: 100%;
  font-size: 2em;
  font-weight: bold;
  color:  ${ siteColors.darkest };
  background-color: ${ siteColors.main };
border-top-left-radius: 0.5em;
border-top-right-radius: 0.5em;
  @media (max-width: ${ breakpoints.desktopSD }px) {
    font-size: 1.4em;
  }
  @media (max-width: ${ breakpoints.iPad }px) {
    font-size: 1.2em;
  }
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    font-size: 1.0em;
  }
`;

const CaseStudyCopy = styled.div`
  width: 100%;
  column-width: 22em;
column-gap: 3em;
  column-fill: balance;
padding-left: 2em;
padding-right: 2em;
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
`;

const SeaDragonImageContainer=styled.div`
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: center;
padding-top: 2em;
padding-left: 2em;
padding-right: 2em;
padding-bottom: 0;
  @media (max-width: ${ breakpoints.xLargePhone }px) {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
`;

const SeaDragonImageComponent=styled.img`
width: 100%;
padding-bottom: 0;
margin-bottom: 0;
`;

const SeaDragonStudy = () => (
        <>
        <HeaderContainer>
        Contour Rig Tools in Action
        </HeaderContainer>
        <CaseStudyContainer>
          <CaseStudyHeader>
        Carnival Cruise Lines &ldquo;Sea Dragon Tale&rdquo;
          </CaseStudyHeader>
        <AdaptiveHostedVideo url="https://youtube.com/embed/MC9BLRuI3NI" title="Carnival Sea Dragon" />
        <CaseStudyCopy>
        <div>Rig Tools made this piece for Carnival Cruise Lines and the ad agency Arnold possible&mdash;even though it was not used to deform a single mesh.</div>
        <br />

        <div>Our brief for the “Sea Dragon Tail” was to make it feel like something a child might make out of construction paper&mdash;appropriate for a piece whose audio was recorded from a free-associating seven-year-old. Art direction required characters whose bodies were made out of overlapping pieces&mdash;a boy made from strips of paper; an ogre made from ragged, ripped bits; and the titular sea dragon, made out of 152 overlapping scales. And, it had to be done quickly. Due to existing deadlines, the whole piece had to be produced in two weeks, starting with only the soundtrack and rough storyboards.</div>
        <br />

        <div>To make this possible, we rigged every character using Rig Tool’s deform field constraint. The constraint “deforms” the translation and rotation of a transform node, giving it precisely the same effect as if it were a point on a mesh being deformed by the same deform field. This allowed us to create rigs that drove characters made out of little bits of paper almost effortlessly.</div>
        <br />

        <div>Without Rig Tools, we’d have been forced to fall back on more complex solutions that would have been far more time-consuming to implement. For instance, we might have chosen to attach the scales to a deforming piece of geometry via hair follicle nodes. This would have allowed us to “deform” the position of the paper layers, but not to deform it well&mdash;acute angles would have seen the scales or fur bits bunch up and pass through each other, necessitating a great deal of additional effort to smooth out the results. This would have been particularly noticeable on the dragon, whose body was one smooth spline that could be pulled into almost any shape.</div>
        <br />

        <div>The Rig Tool’s deform field is designed to intelligently spread a mesh around sharp bends to produce excellent deformation. Applying it to this problem via the deform field constraint automatically produced perfect behavior that never flipped or interpenetrated. Rigging the Sea Dragon’s 152 scales quite literally took a single command, with no additional adjustment needed. We were able to go from rigging to animation in a single day for all three characters, essential to making our tight deadline. According to Arnold, it was the most successful single piece of content Carnival released in 2014.</div>
        </CaseStudyCopy>
        <SeaDragonImageContainer>
        <SeaDragonImageComponent src={SeaDragonImage} />
        </SeaDragonImageContainer>
        </CaseStudyContainer>
        </>
);

const NewPioneersStudy = () => (
        <CaseStudyContainer>
          <CaseStudyHeader>
            The New Pioneers
          </CaseStudyHeader>
        <AdaptiveHostedVideo url="https://player.vimeo.com/video/145156619?byline=0&portrait=0" title="The New Pioneers" />
        <CaseStudyCopy copyHeight="19em">
<div>Bitfilms and Anzovin Studio collaborated on this animated short, directed by Bitfilm’s Chris Perry. In addition to being used to pitch a TV series concept, the short doubled as an experiment in stylized CG animation techniques. The three and a half minute short was produced from stem to stern in just four months.</div>
        <br />

        <div>To achieve The New Pioneer’s fluid animation style, we needed to be able to distort the characters in ways that couldn’t be accommodated by an ordinary rig--especially when the film’s teenage protagonist, Mynn, performs parkour moves that required her body to assume extreme shapes. Rig tools spline-based rigging made this easy, and Mynn’s body rig was implemented from scratch in a single day, and being used for animation testing the next. Since Mynn’s rig doubled as the rig for every other human character in the short, this let us hit full production in just weeks from a standing start--something that would have been impossible without rig development this rapid.</div>
        <br />

        <div>Rig Tools also made “Benny,” the large red beetle-like creature Mynn faces, possible. Unlike the human characters, Benny had lots of body details, including spikes, fins, and armor plates, all of which had to behave coherently as his body deformed. Once again, Rig Tools deform field constraint--which drives transform nodes with the same field used to deform meshes--proved invaluable, allowing rigid pieces with their own rigging to inherit his overall body deformation. Deform field constraints were even used to attach his shoulders and hips.</div>
        </CaseStudyCopy>
        </CaseStudyContainer>
);

const ACAStudy = () => (
        <CaseStudyContainer>
          <CaseStudyHeader>
        American Canoe Association &ldquo;Ten Tips for Boating Safely&rdquo;
          </CaseStudyHeader>
        <AdaptiveHostedVideo url="https://www.youtube.com/embed/RDtF8j_wdOI" title="Ten Tips for Boating Safely" />
        <CaseStudyCopy copyHeight="23em">
        <div>American Canoe Association is a national nonprofit organization devoted to paddling safety. For this piece, the ACA wanted a humorous character who’s continual failure at basic boating safety would get the organization’s message across in an accessible way. Anzovin Studio came up with Blueregard, a Suessian figure with eyebrows that would be at home on a Muppet, a massive temper, and no common sense. In the grand tradition of cartoon blowhards, he’d be dead ten times over if he wasn’t indestructible.</div>
        <br />

        <div>Though Blueregard is visually simple, his squat body and huge mouth presented rigging challenges that would be painful to solve with conventional rigging techniques. Not only did his body need to show smooth, cartoony curves as it bent and warped--traditionally quite difficult to do with thick objects--but his mouth could make up as much as one third his entire body length, and his mouth controls needed to follow the same curvature as the rest of his body.</div>
        <br />

        <div>This was a perfect use case for Contour Rig Tool’s volume preserving deformation and Deform Field constraint. Multiple versions of Blueregard were modeled with different kinds of mouths, then each mouth was rigged with a set of on-surface shaping controls. These controls were in turn driven by Deform Field constraints, so they matched the deformation of his mesh. Animators could pop between different mouth types and reshape them as needed while maintaining the look and shape of his body.</div>
        <br />

        <div>Blueregard’s body shape also made a lot of poses impossible without extensively stretching his limbs. Contour’s spline-based rigging made these “rubber hose arms” easy to create and animate.</div>
        </CaseStudyCopy>
        </CaseStudyContainer>
);

const CaseStudiesPage = () => (
        <Layout>
        <SEO title= "Case Studies" />
        <CaseStudiesContainer>
              <SeaDragonStudy />
              <NewPioneersStudy />
              <ACAStudy />
        </CaseStudiesContainer>
        </Layout>
      );


export default CaseStudiesPage;
